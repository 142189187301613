import React, { useCallback, useState } from 'react';
import { cn } from '@rocco/utils/cn';
import { useNavigate } from '@remix-run/react';
import { toast } from 'sonner';
import { HeartIcon } from 'lucide-react';
import { ReactComponent as ArrowRightIcon } from '@rocco/icons/svg/arrow/arrow-right.svg';
import { Button } from '@rocco/components/button';
import type { ButtonProps } from '@rocco/components/button/button';
import { useAtomValue } from 'jotai/index';
import { uiContextAtomDesignBoardsActions } from '@rocco/ui/design-board/components/DesignBoardsActions';
import { DisplayMode } from '@rocco/types/enum/display-mode';

interface TileFollowButtonProps {
    pinId: number;
    pinType:
        | 'ProjectPin'
        | 'ProductPin'
        | 'ProfessionalPin'
        | 'ArticlePin'
        | 'PhotoPin'
        | 'MemberUploadPin';
    followed?: boolean;
}

export const TileFollowButton = (
    props: Omit<ButtonProps, 'size' | 'onClick'> & TileFollowButtonProps,
) => {
    const { pinId, pinType, followed = false, ...rest } = props;
    const designBoardsActions = useAtomValue(uiContextAtomDesignBoardsActions);

    const [isActive, setIsActive] = useState(followed);
    const navigate = useNavigate();

    const handleProductFollow = useCallback(async () => {
        if (designBoardsActions) {
            designBoardsActions.trackingConfig?.onBeforeFollow?.(pinId);

            const board =
                await designBoardsActions.showSelectDesignBoardModal(true);

            if (board) {
                designBoardsActions.trackingConfig?.onAfterBoardSelect?.(board);

                setIsActive(true);

                const addedCount = await designBoardsActions.addItemsToBoard(
                    board.id,
                    pinType,
                    [pinId],
                );

                if (addedCount > 0) {
                    designBoardsActions.trackingConfig?.onAfterFollow?.(pinId);

                    if (
                        designBoardsActions.displayMode === DisplayMode.Desktop
                    ) {
                        toast.success('Saved to Design Board', {
                            action: {
                                label: 'Dismiss',
                                onClick: () => toast.dismiss(),
                            },
                            cancel: {
                                label: (
                                    <span className="flex gap-2 justify-between items-center">
                                        <span>View Design Board</span>
                                        <ArrowRightIcon className="size-4.5" />
                                    </span>
                                ),
                                onClick: () => {
                                    navigate(
                                        `/member/design-boards/${board.urlSegment}`,
                                    );
                                },
                            },
                        });
                    } else {
                        toast.success('Saved to Design Board', {
                            action: {
                                label: 'View',
                                onClick: () => {
                                    navigate(
                                        `/member/design-boards/${board.urlSegment}`,
                                    );
                                },
                            },
                        });
                    }
                }
            }
        }
    }, [pinId, pinType, designBoardsActions]);

    return (
        <Button
            type="button"
            size="square42"
            color="secondary70"
            {...rest}
            onClick={e => {
                // prevent from firing route event
                e.preventDefault();
                e.stopPropagation();
                void handleProductFollow();
            }}
        >
            <HeartIcon
                className={cn(
                    'w-4',
                    isActive ? 'fill-gray-900' : 'stroke-gray-900',
                )}
            />
        </Button>
    );
};

export const mapPinType = (
    type: 'project' | 'product' | 'professional' | 'article',
) => {
    switch (type) {
        case 'project':
            return 'ProjectPin';
        case 'product':
            return 'ProductPin';
        case 'professional':
            return 'ProfessionalPin';
        case 'article':
            return 'ArticlePin';
    }
};
